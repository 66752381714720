<template>
  <layout-vertical>
    <router-view />

    <template #footer>
      <component :is="layoutFooter" />
    </template>
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import DashboardFooter from '@/views/client/dashboard/components/Footer'
import AppFooter from '@core/layouts/components/AppFooter.vue'

export default {
  components: {
    LayoutVertical,
    DashboardFooter,
    AppFooter
  },
  computed: {
    layoutFooter() {
      if (this.$route.name === null) return '';
      return this.$route.name.split('.').includes('dashboard') ? 'dashboard-footer' : 'app-footer'
    }
  }
}
</script>
